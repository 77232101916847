import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './HistoryPage.css'; // Make sure to import your CSS file
import Latex from 'react-latex-next';

const HistoryPage = () => {
  const [history, setHistory] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        fetchHistory(user.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchHistory = async (uid) => {
    const userDoc = doc(db, 'users', uid);
    const docSnapshot = await getDoc(userDoc);
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const historyData = userData.history || [];

      // Sort historyData by timestamp in descending order
      const sortedHistoryData = historyData.sort((a, b) => {
        const timeA = a.timestamp.seconds ? a.timestamp.seconds * 1000 : new Date(a.timestamp).getTime();
        const timeB = b.timestamp.seconds ? b.timestamp.seconds * 1000 : new Date(b.timestamp).getTime();
        return timeB - timeA;
      });

      setHistory(sortedHistoryData);
    }
  };

  return (
    <div className="history-page">
      <div className="top-left-container">
        <Link to="/" className="home-link">
          <button className="back-button">Back to Home</button>
        </Link>
      </div>
      <h1 className="question-history-title">Question History</h1>
      <div className="content-container">
        {history.length === 0 ? (
          <p>No history available. Ask some questions and they will show up here!</p>
        ) : (
          <ul className="history-list">
  {history.slice().reverse().map((entry, index) => (
    <li key={index} className="history-entry">
      <p><strong>Question:</strong> <Latex>{typeof entry.question === 'string' ? entry.question : ''}</Latex></p>
      <p><strong>Date:</strong> {new Date(entry.timestamp.seconds ? entry.timestamp.seconds * 1000 : entry.timestamp).toLocaleString()}</p>
      <div className='steps-block'><strong>Solution Steps:</strong>
        {entry.solutionSteps.map((step, stepIndex) => (
          <div key={stepIndex} className="latex-display">
            <div className="step-number">{`Steps`}</div>
            <div style={{ marginLeft: '0px' }} className='latex-text'>
              {typeof step === 'string' && step.includes('[Step]:') ? (
                step.split('[Step]:').map((substep, subIndex) => (
                  <div key={subIndex} style={{ marginTop: '30px' }}>
                    <Latex>{substep.trim()}</Latex>
                  </div>
                ))
              ) : (
                <Latex>{typeof step === 'string' ? step : ''}</Latex>
              )}
            </div>
          </div>
        ))}
      </div>
    </li>
  ))}
</ul>
        )}
      </div>
    </div>
  );
};

export default HistoryPage;