import React from 'react';
import { auth, GoogleAuthProvider } from './firebase-config';
import './Login.css';
import { signInWithPopup } from 'firebase/auth';
import { ReactComponent as ZeroSVG } from './zero_name.svg';

const LoginComponent = () => {
    const handleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-header">
                <div className="header-svg-text">
                    <ZeroSVG style={{ height: '8em', width: 'auto', verticalAlign: 'middle' }} />
                </div>
                <h3>AI Homework Helper & Math Tutor</h3>
            </div>
            <button onClick={handleLogin} className="login-button">
                <i className="fab fa-google" style={{ marginRight: '15px' }}></i>Continue with Google
            </button>
            <video
                src="/background.mp4"
                autoPlay
                loop
                muted
                playsInline
                className="background-video"
            >
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default LoginComponent;
