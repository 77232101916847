import React, { useState, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { auth, db } from './firebase-config';
import { getFirestore, doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import LoginComponent from './LoginComponent';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { ReactComponent as ZeroSVG } from './zero_name.svg';
import HistoryPage from './HistoryPage';

const GA_MEASUREMENT_ID = 'G-F62N83294G';
ReactGA.initialize(GA_MEASUREMENT_ID);

const MainApp = () => {
  const [input, setInput] = useState('');
  const [questionAsked, setQuestionAsked] = useState(false);
  const [solutionSteps, setSolutionSteps] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [startSolving, setStartSolving] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  const [QGenQuery, setQGenQuery] = useState(false);
  const [questionGenerated, setQuestionGenerated] = useState('');
  const [startGenerating, setStartGenerating] = useState(false);
  const [mode, setMode] = useState('solver');
  const maxRequestCount = 30;
  const [feedbackList, setFeedbackList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  const [imageLoading, setImageLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [user, setUser] = useState('empty');
  const [questionsLeft, setQuestionsLeft] = useState(5);
  const [dialogVisible, setDialogVisible] = useState(false);
  const menuRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timerRef.current);
    setPopupVisible(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setPopupVisible(false);
    }, 1000);
  };

  const handleInputChange = (e) => setInput(e.target.value);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        checkAndInitializeUser(user.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        setUser(null);
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const checkAndInitializeUser = async (uid) => {
    const userDoc = doc(db, "users", uid);
    const docSnapshot = await getDoc(userDoc);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const setupTime = userData.setupTime.toDate();
      const currentTime = new Date();
      const timeDifference = currentTime - setupTime;
      const oneMonth = 1000 * 60 * 60 * 24 * 30;

      if (timeDifference >= oneMonth) {
        await updateDoc(userDoc, { questionsLeft: 5, setupTime: currentTime });
        setQuestionsLeft(5);
      } else {
        setQuestionsLeft(userData.questionsLeft);
      }
    } else {
      const setupTime = new Date();
      await setDoc(userDoc, { questionsLeft: 5, setupTime });
      setQuestionsLeft(5);
    }
  };

  const decrementQuestionCount = async () => {
    const userDoc = doc(db, "users", user.uid);
    const docSnapshot = await getDoc(userDoc);
    if (docSnapshot.exists()) {
      const oldCount = docSnapshot.data().questionsLeft;
      if (oldCount === 0) {
        setDialogVisible(true);
      }
      else {
        const newCount = docSnapshot.data().questionsLeft - 1;
        if (newCount >= 0) {
          await updateDoc(userDoc, { questionsLeft: newCount });
          setQuestionsLeft(newCount);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      previewAndUploadImage(file);
    }
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const file = item.getAsFile();
        previewAndUploadImage(file);
      }
    }
  };

  const previewAndUploadImage = (file) => {
    setImageLoading(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    uploadImage(file);
    ReactGA.event({
      category: 'User',
      action: 'Uploaded Image',
      label: 'Math Solver + Question Generator',
    });
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://math-flask.onrender.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setInput(response.data.text);
      setImageLoading(false);
    } catch (error) {
      console.error('Error uploading image:', error);
      setImageLoading(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const MAX_RETRIES = 10;
  const RETRY_DELAY_MS = 1000;

  const fetchSolution = useCallback(async () => {
    if (isFetching || !startSolving || requestCount >= maxRequestCount) {
        return;
    }

    console.log('Fetching solution...');

    setIsFetching(true);
    setSolutionSteps([]); // Reset the solution steps before starting the stream
    let retryAttempt = 0;
    const userId = user.uid;

    while (retryAttempt < MAX_RETRIES) {
        try {
            const response = await fetch('https://math-flask.onrender.com/solve_v4', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    problem: input,
                    userId: "Web: " + userId,
                }),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            let partialResult = "";

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                partialResult += decoder.decode(value, { stream: true });

                // Update the solution step by step
                setSolutionSteps([partialResult]); // Update with the latest chunk only
            }

            if (response.ok) {
                setRequestCount(prevCount => prevCount + 1);
                setStartSolving(false);
                retryAttempt = MAX_RETRIES;
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
            retryAttempt++;
            if (retryAttempt < MAX_RETRIES) {
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
            } else {
                console.error('Max retries reached.');
            }
        } finally {
            setIsFetching(false);
        }
    }
}, [input, isFetching, startSolving, requestCount, user]);

  const fetchQuestion = useCallback(async () => {
    if (isFetching || !startGenerating || requestCount >= maxRequestCount) {
      return;
    }

    console.log('Fetching question...');

    setIsFetching(true);
    let retryAttempt = 0;

    while (retryAttempt < MAX_RETRIES) {
      try {
        const response = await fetch('https://math-flask.onrender.com/genque', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refText: input,
          }),
        });
        const data = await response.json();

        if (response.ok) {
          setQuestionGenerated(data.question);
          retryAttempt = MAX_RETRIES;
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error:', error);
        retryAttempt++;
        if (retryAttempt < MAX_RETRIES) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
        } else {
          console.error('Max retries reached.');
        }
      }
    }
    setIsFetching(false);
  }, [input, isFetching, startGenerating, requestCount]);

  useEffect(() => {
    if (startSolving) {
      fetchSolution();
    }
  }, [startSolving, fetchSolution]);

  useEffect(() => {
    let isActive = true;

    if (startGenerating && isActive) {
      fetchQuestion().then(() => {
        if (isActive) {
          setStartGenerating(false);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [startGenerating, fetchQuestion]);

  const handleSubmit = async () => {
    if (questionsLeft === 0) {
      setDialogVisible(true);
    } else {
      setQuestionAsked(true);
      setSolutionSteps([]);
      setIsFetching(false);
      setStartSolving(true);
      setRequestCount(0);
      decrementQuestionCount();
      ReactGA.event({
        category: 'User',
        action: 'Asked a Question',
        label: 'Math Solver',
      });

      await logUserActivity();
    }
  };

  const logUserActivity = async () => {
    const userId = user.uid;
    const logDoc = doc(db, "logs", userId);
    const docSnapshot = await getDoc(logDoc);
    const currentTime = Timestamp.now();

    if (docSnapshot.exists()) {
      const logData = docSnapshot.data();
      const firstActive = logData.firstActive.toDate();
      const lastActive = logData.lastActive.toDate();
      const activeDays = Math.floor((currentTime.toDate() - firstActive) / (1000 * 60 * 60 * 24)) + 1;

      await updateDoc(logDoc, {
        lastActive: currentTime,
        activeDays: activeDays
      });
    } else {
      await setDoc(logDoc, {
        firstActive: currentTime,
        lastActive: currentTime,
        activeDays: 1
      });
    }
  };

  const handleqgenSubmit = () => {
    setQGenQuery(true);
    setQuestionGenerated('');
    setIsFetching(false);
    setStartGenerating(true);
    setRequestCount(0);
    ReactGA.event({
      category: 'User',
      action: 'Generated a Question',
      label: 'Question Generator',
    });
  };

  const handleNewQuestionGen = () => {
    setInput('');
    setQGenQuery(false);
    setQuestionGenerated('');
    setIsFetching(false);
    setStartGenerating(false);
    setSelectedFile(null);
    setRequestCount(0);
  };

  const handleNewQuestion = () => {
    setInput('');
    setQuestionAsked(false);
    setSolutionSteps([]);
    setIsFetching(false);
    setStartSolving(false);
    setSelectedFile(null);
    setRequestCount(0);
  };

  const getEasyQuestion = () => {
    setInput(questionGenerated + ' Give an easier question on the same topic.');
    setStartGenerating(true);
    setQuestionGenerated('');
  };

  const getHarderQuestion = () => {
    setInput(questionGenerated + ' Give a harder question on the same topic.');
    setStartGenerating(true);
    setQuestionGenerated('');
  };

  const renderLoaderOrSolution = () => {
    if (solutionSteps.length > 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div className="latex-display">
            {solutionSteps.map((step, index) => (
              <div key={index} className='latex-text'>
                <Latex>{step}</Latex>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (isFetching) {
      return (
        <div className="loading-container">
          <div className="loader"></div>
          <p className="thinking-text">Thinking<span className="blinking-cursor">_</span></p>
        </div>
      );
    }
    return null;
  };

  const renderLoaderOrQuestion = () => {
    if (questionGenerated) {
      return (
        <div className="latex-display">
          <Latex>{`Question: ${questionGenerated}`}</Latex>
        </div>
      );
    } else if (isFetching) {
      return (
        <div className="loading-container">
          <div className="loader"></div>
          <p className="thinking-text">Thinking<span className="blinking-cursor">_</span></p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="App">
      <div className="svg-background"></div>
      {user === 'empty' ? <div className="loading-container-splash">
        <div className="loader"></div>
        <p className="thinking-text">Loading<span className="blinking-cursor">_</span></p>
      </div>
        :
        ((user) ? (
          <div>
            <div className='instagram'>
            <a href="https://www.instagram.com/zeromathai/" className='instagram-link'>
              <i className="fab fa-instagram"></i> Follow Us
            </a>
            </div>
            <div className="discord">
            <a href="https://discord.gg/6JFM99SYN4" className='discord-link'>
              <i className="fab fa-discord"></i> Join Our Discord
            </a>
            </div>
            
            <div className="menu-button-container" ref={menuRef}>
              <button onClick={toggleMenu} className="menu-button">
                <i className="fas fa-bars"></i>
              </button>
              {menuOpen && (
                <div className="menu-popup">
                  <button onClick={handleSignOut} className="menu-item">LogOut</button>
                </div>
              )}
            </div>
            <div className='questions-left'>
        <div
          className='question-text'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {questionsLeft} Questions Left
          <div className={`popup ${popupVisible ? 'visible' : ''}`}>
            Join our Discord to request a subscription for unlimited questions
            <br />
            <a href="https://discord.gg/6JFM99SYN4" className='discord-link'>
              <i className="fab fa-discord"></i> Join our Discord!
            </a>
          </div>
        </div>
      </div>
            <div className="header" style={{ marginTop: (questionAsked || QGenQuery) ? '0em' : '14em' }}>
              <header>
              <div className="header-svg-text">
              <ZeroSVG style={{ height: '8em', width: 'auto', verticalAlign: 'middle' }} />
  </div>
                <h3>AI Math Tutor</h3>
              </header>
              <main className='main-box'>
              {(!questionAsked && !QGenQuery) && (
    <div className="app-switch">
      <div className="mode-switch" style={{ display: 'flex', gap: '10px' }}>
        <div
          onClick={() => setMode('solver')}
          className={`mode-option ${mode === 'solver' ? 'active-mode' : ''}`}
        >
          Math Solver
        </div>
        <div
          onClick={() => setMode('question_generator')}
          className={`mode-option ${mode === 'question_generator' ? 'active-mode' : ''}`}
        >
          Question Maker
        </div>
      </div>
    </div>
  )}
                {(!questionAsked && !QGenQuery) ? (
                  <div className="question-input">
                    <div className="textbox">
                      <textarea
                        value={input}
                        onChange={handleInputChange}
                        placeholder={!imageLoading ? (mode === 'solver' ? "Enter your math question here or upload/paste an image of the question." : "Enter the topic you want to generate questions on. You can also upload/paste an image of a related question.") : ""}
                      />
                      {imageLoading && <div className="image-loader"></div>}
                      {imageLoading && <p className="thinking-text">Processing Image<span className="blinking-cursor">_</span></p>}
                    </div>
                    <div className="button-group">
                      <input
                        type="file"
                        id="file"
                        className="file-input"
                        onChange={handleFileChange}
                      />
                      {!selectedFile ? (
                      ( !imageLoading && <label htmlFor="file" className="upload-button-label">Upload Image</label>)
                      ) : (
                       ( !imageLoading && <label htmlFor="file" className="upload-button-label">Upload New Image</label>)
                      )}
                      {selectedFile && <span className="file-name-display">{selectedFile.name}</span>}
                      {mode === 'solver' ? (
                        input && <button onClick={handleSubmit} className="solve-button">Solve Question</button>
                      ) : (
                        input && <button onClick={handleqgenSubmit} className="solve-button">Generate Question</button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='solution-container'>
                    {!QGenQuery ? (
                      <div className="latex-display-question">
                        <Latex>Problem: {input}</Latex>
                      </div>
                    ) : (
                      <div className="latex-display-question">
                        <Latex>Reference Text: {input}</Latex>
                      </div>
                    )}
                    {!QGenQuery ? renderLoaderOrSolution() : renderLoaderOrQuestion()}
                  </div>
                )}
                <div>
                  <div className="button-group">
                    {(QGenQuery && !isFetching) && <button onClick={getEasyQuestion}>Get Easier Question</button>}
                    {(QGenQuery && !isFetching) && <button onClick={getHarderQuestion}>Get Harder Question</button>}
                  </div>
                  <div className="button-group">
                    {(questionAsked) && <button onClick={handleNewQuestion}>Ask Another Question</button>}
                    {(QGenQuery && !isFetching) && <button onClick={handleNewQuestionGen}>Generate Another Question</button>}
                  </div>
                </div>
              </main>
            </div>
            {dialogVisible && (
              <div className="dialog">
                <div className="dialog-content">
                  <p>Contact Support via Email or Discord to increase your question limit to ask more questions to ZERO!</p>
                  <button onClick={() => setDialogVisible(false)}>Close</button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <LoginComponent />
        ))}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/history" element={<HistoryPage />} />
      </Routes>
    </Router>
  );
};

export default App;
